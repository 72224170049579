// @ts-strict-ignore
import Highcharts from 'other_components/highcharts';
import _ from 'lodash';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { display12HrClock, displayMonthDay, formatDuration, getMoment } from '@/datetime/dateTime.utilities';
import { cloneDeepOmit, diffItemArrays, pointInRectangle } from '@/utilities/utilities';
import {
  ALPHA_CAPSULE_UNSELECTED,
  ALPHA_UNSELECTED,
  AUTO_UPDATE,
  CapsuleTimeColorMode,
  DASH_STYLES,
  ITEM_CHILDREN_TYPES,
  ITEM_FIELDS_TO_DIFF,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  PREVIEW_ID,
  SAMPLE_OPTIONS,
  SHADED_AREA_DIRECTION,
  TREND_TOP_Y_AXIS_ID,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import {
  anyLabelsOnLocation,
  createPlotBandAxisDefinition,
  createYAxisDefinition as createYAxisDefinitionSqLabel,
  getCapsuleAxisDefinition,
  getCapsuleAxisId,
  getGridlineWidth,
} from '@/utilities/label.utilities';
import { drawCapsuleRegion as drawCapsuleRegionSqChartRegion } from '@/utilities/chartRegion.utilities';
import { drawSelectedRegion as drawSelectedRegionSqChart } from '@/utilities/chartSelection.utilities';
import {
  clipSignalsToLanes as clipSignalsToLanesImport,
  getAxisItem,
  getChartSeriesDisplayHeight as getChartSeriesDisplayHeightImport,
  getItemYAxis as getItemYAxisImport,
  getNumericTickPositions as getNumericTickPositionsImport,
  getSeriesForYAxisInteraction as getSeriesForYAxisInteractionImport,
  manageAxisOffsets,
  manageLaneLabelDisplay as manageLaneLabelDisplayImport,
  processYAxisChangesPerLane,
  setPointsOnly,
  updateCapsuleAxis as updateCapsuleAxisImport,
  updatePlotBands as updatePlotBandsImport,
  updateSeriesYExtremes as updateSeriesYExtremesImport,
  updateYAxisAlignment as updateYAxisAlignmentImport,
  yAxisFormatter,
  yAxisStringFormatter,
} from '@/utilities/chartHelper.utilities';
import {
  ALPHA_DIMMED,
  BAR_CHART_ESSENTIALS,
  CAPSULE_TYPES,
  DISABLED_MARKER,
  ENABLED_MARKER,
  ENABLED_MARKER_ZERO,
  ENABLED_MARKER_ZERO_UNCERTAIN,
  LINE_WIDTHS,
  SeriesGroupedByAxis,
  SHADOW_AGGREGATE,
  Y_AXIS_MIN_TOTAL_WIDTH,
} from '@/chart/chart.constants';
import { ChartInfoProps, ChartViewActions } from '@/trend/ChartView.organism';
import { CursorsService } from '@/trend/trendViewer/cursors.service';
import { DEFAULT_AXIS_LABEL_COLOR, Z_INDEX } from '@/trend/trendViewer/trendViewer.constants';
import { AxisExtremeChange, createAxisControl } from '@/utilities/axisControl.utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { chartLanes } from '@/utilities/chartLanes';

export interface ChartServiceProps extends ChartInfoProps, ChartViewActions {
  prevItems: any[];
  chart: Highcharts.Chart;
  chartElement: HTMLElement;
}

export function ChartDrawService(initialSettings: ChartServiceProps, $element) {
  const instanceSettings = _.cloneDeep(initialSettings);

  const Z_INDEX_BOOST = 1;
  Z_INDEX[ITEM_TYPES.SERIES] = 1;
  Z_INDEX[ITEM_TYPES.CAPSULE] = 3 + Z_INDEX_BOOST;
  Z_INDEX[ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE] = 4 + Z_INDEX_BOOST;
  Z_INDEX[ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE] = 4 + Z_INDEX_BOOST;

  const sqCursors = new CursorsService();

  // Used by scroll and zoom operations
  let deactivateScrollZoom = _.noop;
  const axisControl = createAxisControl({
    x: {
      getExtremes: () =>
        instanceSettings.isCapsuleTime
          ? {
              min: 0 + instanceSettings.capsuleTimeOffsets.lower,
              max: instanceSettings.longestCapsuleSeriesDuration + instanceSettings.capsuleTimeOffsets.upper,
            }
          : {
              min: instanceSettings.trendStart,
              max: instanceSettings.trendEnd,
            },
      updateExtremes: (newExtremes) => {
        const newExtreme = _.first(newExtremes);
        updateXRange(newExtreme.changeInLow, newExtreme.changeInHigh);
      },
    },
    y: {
      getExtremes: (axis) => {
        const item = getAxisItem(instanceSettings.items, axis);
        return {
          min: Number(item.yAxisMin),
          max: Number(item.yAxisMax),
          axisAlign: item.axisAlign,
        };
      },
      updateExtremes: (axisExtremeChanges: AxisExtremeChange[]) => {
        const axisExtremeChangesItems = _.map(axisExtremeChanges, (changes) => ({
          axisAlign: changes.oldExtremes.axisAlign,
          min: changes.oldExtremes.min + changes.changeInLow,
          max: changes.oldExtremes.max + changes.changeInHigh,
        }));

        instanceSettings.setYExtremes(axisExtremeChangesItems);
      },
      getAxesUnderCursor: getYAxesUnderCursor,
    },
  });

  const drawSelectedRegion = drawSelectedRegionSqChart(
    () => instanceSettings.chart,
    {
      selection: Z_INDEX.SELECTED_REGION,
      button: Z_INDEX.SELECTED_REGION_REMOVE,
    },
    {
      clearSelection: () => instanceSettings.removeSelectedRegion(),
    },
    {
      x: undefined,
      y: (chart, minVal, maxVal) => ({
        minPixel: 0,
        maxPixel: getChartSeriesDisplayHeight(),
      }),
    },
    {
      isPickingMode: () => instanceSettings.isPickingMode,
      pickSelection: () => instanceSettings.pickSelectedRegion(),
    },
  );

  const drawCapsuleRegion = drawCapsuleRegionSqChartRegion(
    () => instanceSettings.chart,
    () =>
      _.chain(instanceSettings.items)
        .filter(['itemType', ITEM_TYPES.CAPSULE])
        .flatMap((capsuleSet) => _.map(capsuleSet.capsules, (capsule) => ({ ...capsule, lane: capsuleSet.lane })))
        .filter((capsule) => instanceSettings.annotatedItemIds[capsule.id])
        .map(({ startTime, endTime, id, yValue, lane }) => ({
          xMin: startTime,
          xMax: endTime,
          yMin: 0,
          yMax: 0,
          id,
          dateTime: getMoment(startTime).format('lll'),
          yValue,
          lane,
        }))
        .value(),
    {
      selection: Z_INDEX.CAPSULE_REGION,
      button: Z_INDEX.CAPSULE_REGION_REMOVE,
    },
    {
      openAnnotation: (capsuleId) => {
        instanceSettings.showAnnotationEntry(instanceSettings.annotatedItemIds[capsuleId]);
      },
    },
    () => instanceSettings.items,
    {
      x: undefined,
      y: (chart, minVal, maxVal) => ({
        minPixel: 0,
        maxPixel: getChartSeriesDisplayHeight(),
      }),
    },
    {
      isPickingMode: () => instanceSettings.isPickingMode,
      pickSelection: () => instanceSettings.pickSelectedRegion(),
    },
  );

  let lineBreaks = [];
  let capsuleIcons = [];

  // Used to cancel the selection when the mouse leaves the graph
  let mouseOverChart = false;
  const throttledChartMouseMove = _.throttle(chartMouseMove, 50);
  const capsuleLaneHeight = 0;
  let showLabelsOnAxis = anyLabelsOnLocation(instanceSettings.labelDisplayConfiguration, LABEL_LOCATIONS.AXIS);
  let laneClipRect = null;

  const timeZoneOffsetFn = () => {
    /**
     * Use moment-timezone.js to return the timezone offset for individual
     * timestamps, used in the X axis labels and the tooltip header.
     */
    return (timestamp: number) => {
      if (instanceSettings.selectedTimezone) {
        return -moment.tz(timestamp, instanceSettings.selectedTimezone.name).utcOffset();
      }

      return -moment(timestamp).utcOffset();
    };
  };

  Highcharts.setOptions({
    time: {
      // In order to override the getTimezoneOffset method below, useUTC must be true
      useUTC: true,
      getTimezoneOffset: timeZoneOffsetFn(),
    },
  });

  instanceSettings.chartElement = $element;
  instanceSettings.chart = null;

  /**
   * Syncs with the trendstore
   *
   * @param changedField - the path that changed
   */
  function syncTrendStore(changedField: string) {
    const updateCapsuleLabels = changedField === 'showCapsuleLaneLabels';
    const updateLaneLabels = ['customizationMode', 'labelDisplayConfiguration'].includes(changedField);
    const updateGridlines = changedField === 'showGridlines';

    showLabelsOnAxis = anyLabelsOnLocation(instanceSettings.labelDisplayConfiguration, LABEL_LOCATIONS.AXIS);

    if (instanceSettings.chart) {
      if (updateCapsuleLabels) {
        updateCapsuleAxis();
        createCapsuleIcons();
        manageYAxis();
      }

      if (updateLaneLabels || updateCapsuleLabels) {
        manageYAxis();
        chartRedraw();
        updateChartSizing();
        clipSignalsToLanes();
      }

      if (updateGridlines) {
        updateGridlineWidth();
        chartRedraw();
      }
    }
  }

  /**
   * Update the cursors and redraw them on the chart
   */
  function syncCursors() {
    sqCursors.syncCursorsWithStore(
      instanceSettings.isCapsuleTime,
      instanceSettings.sqCursorStoreData,
      instanceSettings.sqTrendStoreData,
      instanceSettings.breaks,
    );
    sqCursors.drawCursors(
      instanceSettings.chart,
      capsuleLaneHeight,
      instanceSettings.sqTrendStoreData,
      instanceSettings.sqDurationStoreData,
      instanceSettings.longestCapsuleSeriesDuration,
      instanceSettings.sqCursorStoreData,
      instanceSettings.selectedTimezone.name,
      instanceSettings.items,
      instanceSettings.darkMode,
    );
  }

  /**
   * Redraws the now cursor when auto update is disabled so it disappears from the chart
   */
  function syncAutoUpdate() {
    if (instanceSettings.chart && instanceSettings.autoUpdateMode === AUTO_UPDATE.MODES.OFF) {
      // Redraw now cursor when auto update is disabled so it disappears
      sqCursors.drawNowCursor(
        instanceSettings.chart,
        instanceSettings.sqTrendStoreData,
        instanceSettings.sqDurationStoreData,
        instanceSettings.longestCapsuleSeriesDuration,
      );
    }
  }

  /**
   * Reflow the trend so that it correctly fills its container. Only needs to be called when the chart container
   * has its dimensions changed because another element on the page changed. Debounced to avoid repeated calls
   * during resizing. Contains checks to ensure it noops if the chart has been removed from the actual DOM by the
   * time the debounce invokes the callback.
   */
  const reflowTrend = _.debounce(() => {
    if (instanceSettings.chart && instanceSettings.chartElement.isConnected) {
      instanceSettings.chart.reflow();
      manageYAxis();
      chartRedraw();
      clipSignalsToLanes();
      updateChartSizing();
    }
  }, DEBOUNCE.MEDIUM);

  /**
   * Adjust all manually drawn elements.
   */
  function updateChartSizing() {
    if (!instanceSettings.chart) {
      return;
    }
    updateLineBreaks();
    drawSelectedRegion({
      xMin: instanceSettings.selectedRegion.min,
      xMax: instanceSettings.selectedRegion.max,
      yMin: 0,
      yMax: 0,
    });
    positionCapsuleIcons();
    drawCapsuleRegion();
    sqCursors.clearHoverCursor();
    sqCursors.drawCursors(
      instanceSettings.chart,
      capsuleLaneHeight,
      instanceSettings.sqTrendStoreData,
      instanceSettings.sqDurationStoreData,
      instanceSettings.longestCapsuleSeriesDuration,
      instanceSettings.sqCursorStoreData,
      instanceSettings.selectedTimezone.name,
      instanceSettings.items,
      instanceSettings.darkMode,
    );
  }

  /**
   * Updates the x and y axis gridline width settings on the chart
   */
  function updateGridlineWidth() {
    if (!instanceSettings.chart) {
      return;
    }

    // Update y axes (there may be multiple)
    const yAxisValues = _.chain(instanceSettings.chart.series)
      .filter((series: any) =>
        _.includes([ITEM_TYPES.METRIC, ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR], series.userOptions.itemType),
      )
      .filter('userOptions.yAxis')
      .map((series) => ({
        id: series.userOptions.yAxis,
        gridLineWidth: getGridlineWidth(instanceSettings.showGridlines),
      }))
      .uniqBy('id')
      .value();

    instanceSettings.chart.update(
      {
        xAxis: {
          gridLineWidth: getGridlineWidth(instanceSettings.showGridlines),
        },
        yAxis: yAxisValues,
      },
      false,
    );
  }

  /**
   * Processes changes to the items array.
   *
   * @param newItems - The updated array of all items in the chart
   * @param oldItems - The array of all previous versions of all items in the chart
   */
  function processItems(newItems: readonly any[], oldItems: readonly any[]) {
    let axis;

    const diff = diffItemArrays(newItems, oldItems, ITEM_FIELDS_TO_DIFF, 'id');

    if (!diff.itemsAddedOrRemoved && _.isEmpty(diff.changedProperties)) {
      return;
    }

    let doUpdateY = false;
    // If ID has changed swap has occurred so recreate the chart given that highcharts references IDs in places
    if (diff.changes.id.length) {
      destroyChart();
    }

    if (!instanceSettings.chart) {
      const ommitedData = cloneDeepOmit(newItems, ['data', 'samples']);
      // Note: the order of calls here is important.
      createChart(ommitedData);
      updateSeriesYExtremes(newItems);
      updateXExtremes(false);
      updateCapsuleAxis();
      createCapsuleIcons();
      manageYAxis();
      updateYAxisAlignment();
      clipSignalsToLanes();
      drawSelectedRegion({
        xMin: instanceSettings.selectedRegion.min,
        xMax: instanceSettings.selectedRegion.max,
        yMin: 0,
        yMax: 0,
      });
      drawCapsuleRegion();
      sqCursors.updateCursorItems(
        instanceSettings.chart,
        instanceSettings.items,
        instanceSettings.isCapsuleTime,
        instanceSettings.sqCursorStoreData,
        instanceSettings.sqTrendStoreData,
        instanceSettings.sqDurationStoreData,
        instanceSettings.longestCapsuleSeriesDuration,
      );
      chartRedraw();
      updateLineBreaks();
    } else if (!newItems.length) {
      destroyChart();
    } else {
      if (diff.itemsRemoved) {
        removeSeries(diff.removedItems);
        removeCapsuleIcons(diff.removedItems);
        doUpdateY = true;
      }

      if (diff.itemsAdded) {
        addSeries(cloneDeepOmit(diff.addedItems, ['data', 'samples']));
        createCapsuleIcons();
        drawCapsuleRegion();
        // do add will be true for formula results
        const doAdd = _.some(diff.addedItems, (item: any) => !_.isEmpty(item.data));
        // if the result is from a formula we need to set doUpdateY to true to ensure proper display
        if (doAdd) {
          updateSeriesYExtremes(diff.addedItems);
          doUpdateY = true;
        }
      }

      if (
        diff.hasAnyPropertyChanged([
          'selected',
          'zones',
          'visible',
          'shadedAreaLower',
          'shadedAreaUpper',
          'color',
          'lineWidth',
          'dashStyle',
          'yAxisConfig',
        ]) ||
        diff.itemsAddedOrRemoved
      ) {
        const shouldUpdateData = diff.itemsAddedOrRemoved || diff.hasAnyPropertyChanged(['lineWidth']);
        updateSeries(getSeriesProperties(newItems), shouldUpdateData);
        if (diff.changes.color.length || diff.changes.dashStyle) {
          doUpdateY = true;
        }
      }

      if (diff.hasAnyPropertyChanged(['selected', 'zones']) || diff.itemsRemoved) {
        // NOTE: This must happen after .getSeriesProperties() because it updates the colors of the items
        colorCapsuleIcons();
      }

      if (diff.changes.autoDisabled.length) {
        updateSeriesVisibility(diff.changes.autoDisabled);
      }

      if (diff.changes.data.length) {
        updateSeries(getSeriesProperties(diff.changes.data, newItems));
        createCapsuleIcons();
        drawCapsuleRegion();
      }

      if (diff.changes.yAxisConfig.length) {
        updateSeriesYExtremes(diff.changes.yAxisConfig);
        doUpdateY = true;
      }

      // We need to ensure that the axis min and max are always properly set. If you swap a Signal with another
      // Signal that has the same min/max values then no yAxisConfig changes will be detected and the signal is
      // not displayed as expected as Highcharts is too helpful in setting min/max axis values on data update.
      if (diff.changes.data.length || diff.changes.yAxisType.length) {
        updateSeriesYExtremes(diff.changes.data);
        doUpdateY = true;
      }

      if (diff.changes.sampleDisplayOption.length) {
        setPointsOnly({
          ...instanceSettings,
          items: diff.changes.sampleDisplayOption,
        });
      }

      if (
        diff.changes.rightAxis.length ||
        diff.changes.axisVisibility.length ||
        diff.changes.axisAlign.length ||
        diff.changes.lane.length
      ) {
        doUpdateY = true;
      }

      if (diff.changes.stringEnum.length) {
        // This is a hack required to set the string labels initially,
        // due to the fact that we add the item to the chart prior to getting data
        if (!_.isEqual(_.countBy(oldItems, 'isStringSeries'), _.countBy(newItems, 'isStringSeries'))) {
          removeSeries(diff.changes.stringEnum);
          addSeries(cloneDeepOmit(diff.changes.stringEnum, ['data', 'samples']));
          updateSeriesYExtremes(diff.changes.stringEnum);
          doUpdateY = true;
          updateSeries(getSeriesProperties(newItems));
        } else {
          // This enables us to update the labels on the y axis for string series during pan and zoom
          // without removing and re-adding the series
          _.forEach(diff.changes.stringEnum, (item: any) => {
            axis = getItemYAxis(item);
            axis.update(
              {
                tickPositions: _.map(item.stringEnum, 'key').sort(),
              },
              false,
            );
          });
        }
      }

      if (instanceSettings.isCapsuleTime) {
        if (
          diff.hasAnyPropertyChanged(['duration', 'capsuleSegmentData']) ||
          _.filter(diff.addedItems, ['childType', ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]).length ||
          _.filter(diff.removedItems, ['childType', ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]).length
        ) {
          updateXExtremes(false);
        }

        if (diff.changes.yAlignment.length) {
          updateYAxisAlignment();
        }
      }

      if (
        diff.hasAnyPropertyChanged(['data', 'stringEnum']) ||
        diff.addedItems.length ||
        diff.removedItems.length ||
        diff.changes.lineWidth.length
      ) {
        updateYAxisAlignment();

        if (diff.addedItems.length || diff.removedItems.length || diff.changes.lineWidth.length) {
          updateCapsuleAxis();
          doUpdateY = true;
        }

        drawSelectedRegion({
          xMin: instanceSettings.selectedRegion.min,
          xMax: instanceSettings.selectedRegion.max,
          yMin: 0,
          yMax: 0,
        });
        drawCapsuleRegion();
      }

      if (doUpdateY) {
        manageYAxis();
      }

      chartRedraw();

      // In chain view, an updateSeries call above can shift the axis's left position and the above chartRedraw
      // shifts it back. If we don't update the line breaks here, they could be in the wrong place.
      if (instanceSettings.view === TREND_VIEWS.CHAIN) {
        updateLineBreaks();
      }

      clipSignalsToLanes();
      // positionCapsuleIcons must happen after redraw because that is when capsule points are calculated
      positionCapsuleIcons();
      drawCapsuleRegion();
      colorCapsuleIcons();
      sqCursors.updateCursorItems(
        instanceSettings.chart,
        instanceSettings.items,
        instanceSettings.isCapsuleTime,
        instanceSettings.sqCursorStoreData,
        instanceSettings.sqTrendStoreData,
        instanceSettings.sqDurationStoreData,
        instanceSettings.longestCapsuleSeriesDuration,
      );

      if (diff.hasAnyPropertyChanged(['data', 'stringEnum'])) {
        sqCursors.drawCursors(
          instanceSettings.chart,
          capsuleLaneHeight,
          instanceSettings.sqTrendStoreData,
          instanceSettings.sqDurationStoreData,
          instanceSettings.longestCapsuleSeriesDuration,
          instanceSettings.sqCursorStoreData,
          instanceSettings.selectedTimezone.name,
          instanceSettings.items,
          instanceSettings.darkMode,
        );
      }
    }
  }

  /**
   * Create icons for any annotations that annotate capsules or reference pattern capsules.
   * Note: Capsules with multiple icons are not handled, only one icon per capsule is supported.
   */
  function createCapsuleIcons() {
    if (!instanceSettings.chart || instanceSettings.isCapsuleTime) {
      return;
    }

    _.chain(instanceSettings.items)
      .filter(['itemType', ITEM_TYPES.CAPSULE])
      .forEach((capsuleSet: any) => {
        _.chain(capsuleSet.capsules)
          .filter((capsule: any) => capsule.isReferenceCapsule)
          .forEach((capsule: any) => {
            const icon = _.find(capsuleIcons, ['id', capsule.id]);
            const ANNOTATE_ICON = '\ue905';
            // If icon already exists just update its seriesId since its row position can move
            if (icon) {
              icon.seriesId = capsuleSet.id;
            } else {
              const xLocation = _.max([capsule.startTime, instanceSettings.trendStart]);
              capsuleIcons.push({
                seriesId: capsuleSet.id,
                id: capsule.id,
                x: xLocation,
                icon: instanceSettings.chart.renderer
                  .text(ANNOTATE_ICON, 0, 0) // fc-annotate
                  .attr({
                    class: 'fc cursorPointer',
                    zIndex: Z_INDEX.CAPSULE_ICONS,
                    dateTime: moment(xLocation).format('lll'), // To support test
                  })
                  .on('click', () => instanceSettings.loadToolForEdit(capsuleSet.capsuleSetId))
                  .add(),
              });
            }
          })
          .value();
      })
      .value();

    positionCapsuleIcons();
    colorCapsuleIcons();
  }

  /**
   * Remove icons for annotations that have been deleted and then create icons for new annotations.
   */
  function updateAnnotationIcons() {
    removeCapsuleIcons(
      _.chain(capsuleIcons)
        .reject((icon) => instanceSettings.annotatedItemIds[icon.id])
        .map((icon) => ({ id: icon.seriesId }))
        .value(),
    );
    createCapsuleIcons();
  }

  /**
   * Updates the position of all of the capsule icons to be just to the left of the capsule to
   * which they are associated.
   */
  function positionCapsuleIcons() {
    _.forEach(capsuleIcons, (icon) => {
      let x, y, visibility;
      const series = findChartSeries(icon.seriesId);
      const point = _.find(_.get(findChartSeries(icon.seriesId), 'points'), ['x', icon.x]) as any;
      const box = icon.icon.getBBox();
      if (point && _.isFinite(point.plotX) && _.isFinite(point.plotY)) {
        x = point.plotX + instanceSettings.chart.plotLeft - box.width - 3;
        y = series.yAxis.toPixels((series.yAxis.max - series.yAxis.min) / 2 + series.yAxis.min, false) + box.height / 2;
        visibility = x < instanceSettings.chart.plotLeft ? 'hidden' : 'visible';
        // Prevent occasional console error during initial render due to NaN being passed to Highcharts
        if (_.isFinite(x) && _.isFinite(y)) {
          icon.icon.attr({ x, y, visibility });
        }
      } else {
        icon.icon.attr({ visibility: 'hidden' });
      }
    });
  }

  /**
   * Updates the color of all annotation capsule icons to be the same as the color of the capsule they annotate.
   */
  function colorCapsuleIcons() {
    _.forEach(capsuleIcons, (icon) => {
      const point = _.find(_.get(findChartSeries(icon.seriesId), 'points'), ['x', icon.x]) as any;
      if (point) {
        icon.icon.css({ color: point.color });
      }
    });
  }

  /**
   * Removes the annotation and icons associated with any items that have also been removed.
   *
   * @param removedItems - The items that have been removed from the chart
   */
  function removeCapsuleIcons(removedItems: any[]) {
    _.forEach(removedItems, (item: any) => {
      const annotationIndex = _.findIndex(capsuleIcons, ['seriesId', item.id]);
      if (annotationIndex >= 0) {
        capsuleIcons[annotationIndex].icon.destroy();
        capsuleIcons.splice(annotationIndex, 1);
      }
    });
  }

  /** Creates a Right-Click event listener for a given DOM element from the HighCharts class
   *
   * @see: https://api.highcharts.com/class-reference/Highcharts#.HTMLDOMElement
   *
   * @param  DOMelement - the HTML element to link the event listener to
   * @param callback - the function to execute when the event occurs
   */
  function createRightClickEventListener(DOMelement: HTMLElement, callback: (e: Event) => void) {
    Highcharts.addEvent(DOMelement, 'contextmenu', (e) => {
      e.preventDefault();
      callback(e as Event);
    });
  }

  /**
   * Create chart from the items.
   *
   * @param items - The array of all items to plot on the chart.
   */
  function createChart(items: any[]) {
    let chartConfig;
    let yAxisValues;
    const dateTimeLabelFormats = {
      millisecond: '%l:%M:%S.%L %P',
      second: '%l:%M:%S %P',
      minute: '%l:%M %P',
      hour: '%l:%M %P',
      day: '%b %e',
      week: '%b %e',
      month: "%b '%y",
      year: '%Y',
    };

    if (!displayMonthDay()) {
      dateTimeLabelFormats.day = '%e. %b';
      dateTimeLabelFormats.week = '%e. %b';
    }

    if (!display12HrClock()) {
      dateTimeLabelFormats.millisecond = '%H:%M:%S.%L';
      dateTimeLabelFormats.second = '%H:%M:%S';
      dateTimeLabelFormats.minute = '%H:%M';
      dateTimeLabelFormats.hour = '%H:%M';
    }

    yAxisValues = _.chain(items).map(createYAxisDefinition).compact().uniqBy('id').value();

    yAxisValues.push(createPlotBandAxisDefinition());

    const series = getSeriesProperties(items);

    chartConfig = {
      chart: {
        background: null,
        backgroundColor: null,
        alignTicks: false,
        animation: false,
        ignoreHiddenSeries: false,
        spacing: [0, 5, 5, 0],
        zoomType: 'x',
        boost: {
          enabled: false,
        },
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
        events: {
          click: onChartClick,
          selection(e) {
            instanceSettings.setSelectedRegion(e.xAxis[0].min, e.xAxis[0].max);
            return false;
          },
          load(e) {
            // Activate scroll and zoom functionality. Use returned function to deactivate scroll and zoom.
            deactivateScrollZoom = axisControl.activateScrollZoom(this, instanceSettings.chartElement);
            // Used for adding cursors to the chart
            createRightClickEventListener(e.target.container, onChartClick);
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        ordinal: false,
        type: 'datetime',
        dateTimeLabelFormats,
        // If we don't set minRange, Highcharts will enforce its own computed minRange. This sets the smallest x
        // range to 1 ms.
        minRange: 1,
        // See https://github.com/highslide-software/highcharts.com/issues/4646 for why we set minTickInterval
        minTickInterval: 0,
        tickLength: 5,
        gridLineWidth: getGridlineWidth(instanceSettings.showGridlines),
        labels: {
          enabled: true,
          style: {
            color: DEFAULT_AXIS_LABEL_COLOR,
            fontSize: '12px',
            fontFamily: 'Helvetica, sans-serif',
            paddingTop: '0px',
          },
          formatter: !instanceSettings.isCapsuleTime
            ? null
            : function () {
                return formatDuration(this.value, { simplify: true });
              },
        },
        breaks: instanceSettings.breaks,
        events: {
          setExtremes(e) {
            let thisMin;
            let thisMax;

            // This callback happens a lot during trend scroll and zoom operations.
            if (e.trigger === 'zoom') {
              thisMin = Math.min(e.min, e.max);
              thisMax = Math.max(e.min, e.max);
              instanceSettings.updateDisplayRangeTimes(thisMin, thisMax);
              drawSelectedRegion({
                xMin: instanceSettings.selectedRegion.min,
                xMax: instanceSettings.selectedRegion.max,
                yMin: 0,
                yMax: 0,
              });
              drawCapsuleRegion();
              sqCursors.drawCursors(
                instanceSettings.chart,
                capsuleLaneHeight,
                instanceSettings.sqTrendStoreData,
                instanceSettings.sqDurationStoreData,
                instanceSettings.longestCapsuleSeriesDuration,
                instanceSettings.sqCursorStoreData,
                instanceSettings.selectedTimezone.name,
                instanceSettings.items,
                instanceSettings.darkMode,
              );
              positionCapsuleIcons();
              updateLineBreaks();
            }
          },
        },
      },
      yAxis: yAxisValues,
      plotOptions: {
        series: {
          turboThreshold: 0,
          boostThreshold: 0,
          allowPointSelect: false,
          animation: false,
          cursor: 'pointer',
          linecap: 'square',
          dataGrouping: {
            enabled: false,
          },
          events: {
            click: onPointClick,
          },
          line: {
            connectNulls: false,
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          dataLabels: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'middle',
            style: {
              fontFamily: 'inherit',
              fontSize: '10px',
              fontWeight: 'normal',
            },
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
              lineWidthPlus: 0,
            },
            inactive: {
              enabled: false,
            },
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      series,
    };

    instanceSettings.chart = Highcharts.chart(instanceSettings.chartElement, chartConfig);

    // Set to true when chart is created so cursors will appear if mouse is already over the chart area
    mouseOverChart = true;

    addEventListeners(instanceSettings.chartElement);
  }

  function addEventListeners(element) {
    element.addEventListener('mouseenter', onChartMouseEnter);
    element.addEventListener('mousemove', throttledChartMouseMove);
    element.addEventListener('mouseleave', onChartMouseLeave);
  }

  function removeEventListeners(element) {
    element.removeEventListener('mouseenter', onChartMouseEnter);
    element.removeEventListener('mousemove', throttledChartMouseMove);
    element.removeEventListener('mouseleave', onChartMouseLeave);
  }

  /**
   * Removes the chart.
   */
  function destroyChart() {
    capsuleIcons = [];
    deactivateScrollZoom();
    instanceSettings.clearPointerValues();
    if (instanceSettings.chart && instanceSettings.chart.destroy) {
      instanceSettings.chart.destroy();
      instanceSettings.chart = null;
    }

    if (instanceSettings.chartElement) {
      removeEventListeners(instanceSettings.chartElement);
      instanceSettings.chartElement.innerHTML = '';
    }
  }

  /**
   * Break the line where there is a point break
   */
  function updateLineBreaks() {
    let x;
    _.forEach(lineBreaks, (lineBreak) => {
      lineBreak.destroy();
    });

    lineBreaks = [];

    _.forEach(instanceSettings.breaks, (brk: any) => {
      // note: this check was necessary to avoid some underlying Highcharts issue.
      if (instanceSettings.chart.xAxis[0].brokenAxis.hasBreaks && _.isFinite(brk.from)) {
        x = instanceSettings.chart.xAxis[0].toPixels(brk.from, false);
        if (_.isFinite(x)) {
          lineBreaks.push(
            instanceSettings.chart.renderer
              .rect(x, instanceSettings.chart.plotBox.y, 3, instanceSettings.chart.plotBox.height, 1)
              .attr({
                class: 'highcharts-cursor-crosshair',
                fill: '#ffffff',
                opacity: 1,
                zIndex: Z_INDEX.LINE_BREAKS,
              })
              .add(),
          );
        }
      }
    });
  }

  /**
   * Handles the 'mouseenter' event on the Highcharts DOM element
   */
  function onChartMouseEnter() {
    mouseOverChart = true;
    togglePointerEventsWorkaround(true);
  }

  /**
   * Handles the 'mouseleave' event on the Highcharts DOM element
   */
  function onChartMouseLeave() {
    mouseOverChart = false;
    instanceSettings.clearPointerValues();
    sqCursors.clearHoverCursor();
    togglePointerEventsWorkaround(false);
  }

  /**
   * Workaround for CRAB-42458. This allows the user to click a capsule or signal to select it, but keeps the bug
   * workaround when the user exits the chart.
   *
   * @param isEntering - True if entering the chart, false otherwise
   */
  function togglePointerEventsWorkaround(isEntering: boolean) {
    if (instanceSettings.chartElement) {
      instanceSettings.chartElement.style.pointerEvents = isEntering ? 'auto' : 'fill';
    }
  }

  /**
   * Handles the 'mousemove' event on the Highcharts DOM element
   *
   * @param e - An event object
   */
  function chartMouseMove(e: MouseEvent) {
    if (!instanceSettings.chart) {
      return;
    }

    // Translate the client X-value into the chart area. Note that using e.offsetX is not sufficient, since in
    // some browsers the frame for this value is different when hovering over a series or over the plot background.
    const chartRect = instanceSettings.chart.container.getBoundingClientRect();
    let yValues, xAxis, xValue, xPixelPlotArea;

    // Mouse events are fired when axis is dragged. Updating point values during axis drag causes flickering.
    if (axisControl.isDragInProgress() || !mouseOverChart) {
      instanceSettings.clearPointerValues();
      sqCursors.clearHoverCursor();

      return;
    }

    // Calculate an x-value from the mouse event
    xAxis = instanceSettings.chart.xAxis[0] as Highcharts.Axis;
    xValue = xAxis.toValue(e.clientX - chartRect.left, false);
    xPixelPlotArea = e.clientX - chartRect.left - instanceSettings.chart.plotLeft;

    // Don't try to calculate pointerValues when the cursor is in the y-axis area
    if (xPixelPlotArea >= 0) {
      yValues = sqCursors.calculatePointerValues(
        instanceSettings.chart,
        xValue,
        instanceSettings.items,
        instanceSettings.sqTrendStoreData,
      );

      if (instanceSettings.view === TREND_VIEWS.CAPSULE) {
        yValues = sqCursors.reduceYValues(
          yValues,
          instanceSettings.items,
          chartLanes.getChartSeriesDisplayHeight({ chart: instanceSettings.chart, capsuleLaneHeight: 0 }),
        );
      }
    }

    // This event is triggered when changing item visibility, hence the need for evalAsync
    if (yValues) {
      instanceSettings.setPointerValues(xValue, yValues);
    } else {
      instanceSettings.clearPointerValues();
    }

    if (!_.isNaN(xValue)) {
      sqCursors.drawHoverCursor({
        chart: instanceSettings.chart,
        xPixel: xPixelPlotArea,
        xValue,
        yValues,
        capsuleLaneHeight,
        trendData: instanceSettings.sqTrendStoreData,
        durationData: instanceSettings.sqDurationStoreData,
        longestCapsuleSeriesDuration: instanceSettings.longestCapsuleSeriesDuration,
        cursorData: instanceSettings.sqCursorStoreData,
        timezoneName: instanceSettings.selectedTimezone.name,
        itemsWithLaneInfo: instanceSettings.items,
        darkMode: instanceSettings.darkMode,
      });
    }
  }

  /**
   * Manages the display of lane, axis and capsule lane labels as well as the offset required for y-axis if there
   * is more than one y-axis assigned to a lane.
   */
  function manageLaneAxisLabels() {
    if (!instanceSettings.chart) {
      return;
    }

    // this manages axes labels and titles
    manageAxisOffsets({
      ...instanceSettings,
      addAxisTitle: showLabelsOnAxis,
      capsuleLaneHeight,
      sqTrendStore: instanceSettings.sqTrendStoreData,
    });
    // This may not be needed, but was being called in manageAxisOffsests before this
    manageCapsuleLaneLabels();
    manageLaneLabelDisplay();
  }

  /**
   * Toggles the capsule lane labels on and off based on the showCapsuleLaneLabels flag in the trendStore.
   */
  function manageCapsuleLaneLabels() {
    updateCapsuleAxis();
  }

  /**
   * Create a y-axis definition for an item.
   *
   * This function is called on a per-item basis. This function also ensures that capsules that belong to the same
   * capsule set are placed on the same y-axis so that capsule selection and label display logic work as expected.
   *
   * @param item - The item for which to create a yAxis.
   * @return A yAxis definition object.
   */
  function createYAxisDefinition(item: any): Highcharts.YAxisOptions {
    let yAxis;
    if (item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR) {
      yAxis = createYAxisDefinitionSqLabel({
        item,
        yAxisStringFormatter: yAxisStringFormatter({
          getItems: () => instanceSettings.items,
        }),
        yAxisFormatter: yAxisFormatter({
          ...instanceSettings,
          capsuleLaneHeight,
          getChart: () => instanceSettings.chart,
          getItems: () => instanceSettings.items,
        }),
        tickPositioner: getNumericTickPositions(),
        trendValues: instanceSettings.sqTrendStoreData,
      });
    } else {
      const axisId = getCapsuleAxisId(item.capsuleSetId);
      item.yAxis = axisId;
      if (instanceSettings.chart && instanceSettings.chart.yAxis) {
        yAxis = _.find(instanceSettings.chart.yAxis, {
          userOptions: { id: axisId },
        });
      }
      if (!yAxis) {
        yAxis = getCapsuleAxisDefinition(item, axisId);
      } else {
        if (item.yValue > yAxis.userOptions.customValue) {
          yAxis.update({ max: item.yValue }, false);
        }
        yAxis = null;
      }
    }

    return yAxis;
  }

  /**
   * Returns the Highcharts options to be assigned to the `series` on the chart.
   *
   * Selected items are made to stand out by putting their z-index higher while unselected items are
   * de-emphasized by fading their color. The line width is set based on the type of item.  Selected capsules
   * have their associated series highlighted for the encapsulated region.
   *
   * @see http://api.highcharts.com/highcharts#plotOptions.series
   * @param items - All items being displayed on the chart.
   * @param allItems - when we want to update only the diff items, getSeriesProperties needs to have access to all
   * items in order to check if some are selected and to be able to set the color correctly
   * @return  The items with updated color, zIndex, and lineWidth
   */
  function getSeriesProperties(items: readonly any[], allItems?: readonly any[]): any[] {
    const itemsListToBeVerified = allItems || items;
    const anySeriesSelected = _.some(itemsListToBeVerified, {
      selected: true,
      itemType: ITEM_TYPES.SERIES,
    });
    const anyCapsuleSeriesSelected = _.some(itemsListToBeVerified, {
      selected: true,
      itemType: ITEM_TYPES.CAPSULE,
    });
    const anyCapsulesSelected = _.some(itemsListToBeVerified, {
      anyCapsulesSelected: true,
      selected: anyCapsuleSeriesSelected,
      itemType: ITEM_TYPES.CAPSULE,
    });
    const anyScalarsSelected = _.some(itemsListToBeVerified, {
      selected: true,
      itemType: ITEM_TYPES.SCALAR,
    });

    return _.map(items, (item: any) => {
      let color = item.color;
      let fillColor = item.color;
      let zones = item.zones;
      let lineWidth = !_.isUndefined(item.lineWidth) ? item.lineWidth : LINE_WIDTHS[item.itemType];
      let dashStyle = item.dashStyle || DASH_STYLES.SOLID;
      let zIndex = Z_INDEX[item.itemType];
      let shadow = false as any;
      let marker = DISABLED_MARKER;
      let graphType = 'line';
      let threshold = 0;
      let fillOpacity = 1.0;
      let customProperties = {};
      let dim = false;
      const data = item.data;
      const step = item.interpolationMethod === 'Step' ? 'left' : null;
      const selectedOrPreview =
        item.selected || _.startsWith(item.id, PREVIEW_ID) || _.startsWith(item.capsuleSetId, PREVIEW_ID);

      if (item.itemType === ITEM_TYPES.SERIES) {
        if (anySeriesSelected || anyScalarsSelected) {
          if (selectedOrPreview) {
            // Some of the lines can be very light in gradient mode so the darkest one is used for the selected item
            if (
              instanceSettings.isCapsuleTime &&
              _.includes(
                [CapsuleTimeColorMode.SignalGradient, CapsuleTimeColorMode.ConditionGradient],
                instanceSettings.capsuleTimeColorMode,
              )
            ) {
              color = _.chain(items as any[])
                .filter({ isChildOf: item.isChildOf })
                .filter((i) => _.intersection(i.otherChildrenOf, item.otherChildrenOf).length > 0)
                .minBy((i) => tinycolor(i.color).getBrightness())
                .get('color', color)
                .value();
            }
          } else {
            color = tinycolor(color).setAlpha(ALPHA_UNSELECTED).toString();
          }
        }

        if (instanceSettings.isCapsuleTime) {
          zones = zonesForCapsuleTime(item, color);
        }

        if (selectedOrPreview) {
          zIndex += Z_INDEX_BOOST;
        }

        if (
          !_.includes(
            [SAMPLE_OPTIONS.LINE, SAMPLE_OPTIONS.BAR],
            _.get(item, 'sampleDisplayOption', SAMPLE_OPTIONS.LINE),
          )
        ) {
          const radius = lineWidth + 1;
          if (item.sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE) {
            lineWidth = 0.5;
          } else {
            lineWidth = 0;
          }

          marker = { ...ENABLED_MARKER, radius };
          dashStyle = null;
        }
      } else if (_.includes(CAPSULE_TYPES, item.itemType)) {
        dim = false;
        if (_.isNil(item.capsuleSetId) || item.capsuleSetId === instanceSettings.capsuleEditingId) {
          // Previewed capsules should be highly visible, but the dim must be applied with a selection
          dim = item.anyCapsulesSelected;
        } else if (anyCapsuleSeriesSelected && !selectedOrPreview) {
          // If another capsule series is selected, dim the capsule series including any individually selected
          // capsules by removing the highlighted zones
          dim = true;
          zones = [];
        } else if (anyCapsulesSelected) {
          // This capsule row or another capsule row has a selected capsule so we dim all the rows. The zone
          // property will inform highcharts where to draw the capsules in full color
          dim = true;
        }

        // uncertain capsules are created by overlaying a series with slightly shorter segments - this series is
        // always white. If the alpha of that white series gets set the capsule no longer looks uncertain - so we
        // ensure the alpha does not get changed
        if (dim && color !== '#fff') {
          color = tinycolor(color).setAlpha(ALPHA_CAPSULE_UNSELECTED).toString();
        }

        if (selectedOrPreview || item.anyCapsulesSelected) {
          zIndex += Z_INDEX_BOOST;
        }

        if (item.isAggregated) {
          shadow = _.assign({}, SHADOW_AGGREGATE, { color });
          lineWidth -= 2;
        }

        if (item.marker) {
          marker = item.uncertainZeroLengthOverlay ? ENABLED_MARKER_ZERO_UNCERTAIN : ENABLED_MARKER_ZERO;
        }
      } else if (item.itemType === ITEM_TYPES.SCALAR) {
        if ((anySeriesSelected || anyScalarsSelected) && !selectedOrPreview) {
          color = tinycolor(color).setAlpha(ALPHA_UNSELECTED).toString();
        }

        if (selectedOrPreview) {
          zIndex += Z_INDEX_BOOST;
        }
      } else {
        throw new TypeError(`Invalid Item Type: ${item.itemType}`);
      }

      // Handle items with shaded areas
      if ((item.shadedAreaLower && item.shadedAreaUpper) || item.shadedAreaDirection) {
        zIndex = 0;
        graphType = item.shadedAreaDirection ? 'area' : 'arearange';
        fillOpacity = _.get(item, 'fillOpacity', 0.2);
        color = tinycolor('#ccc').setAlpha(0.5).toString();

        if (item.shadedAreaDirection) {
          threshold = item.shadedAreaDirection === SHADED_AREA_DIRECTION.UP ? Infinity : -Infinity;
        }

        if ((anySeriesSelected || anyScalarsSelected) && !selectedOrPreview) {
          color = tinycolor(color).setAlpha(0).toString();
          fillOpacity = 5 * fillOpacity * ALPHA_UNSELECTED; // Make it lighter to represent deselected
        }
      }

      if (item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
        customProperties = _.assign({}, BAR_CHART_ESSENTIALS);
        if (item.lineWidth) {
          _.assign(customProperties, { pointWidth: item.lineWidth });
        }
      }

      // Ensure that uncertain portions of signal are greyed out when others are selected
      if (item.itemType === ITEM_TYPES.SERIES && item.color !== color && _.some(item.zones, 'dashStyle')) {
        zones = _.map(zones, (zone) => (_.has(zone, 'color') ? _.assign({}, zone, { color }) : zone));
      }

      fillColor = tinycolor(fillColor).setAlpha(fillOpacity).toString();

      return _.assign(
        {
          cursor: 'pointer',
        },
        item,
        {
          zoneAxis: 'x',
          boostThreshold: 0,
          zones,
          type: graphType,
          threshold,
          fillColor,
          data,
          shadow,
          color,
          dashStyle,
          zIndex,
          lineWidth,
          marker,
          step,
          dim,
        },
        customProperties,
      );
    });
  }

  /**
   * Create the zones for a series in capsule time such that the series will be highlighted for the range of time
   * of the capsule that is interested in it. This allows the user to see the range of the series defined by the
   * capsule while being able to see data prior to and after, particularly when scrolling and zooming
   *
   * Update the existing zone list, if one exists, with the capsule time zones so that both display correctly.
   * In order to ensure correct display, return an array where all objects with a value also have the properties
   * color and dashStyle.
   *
   * @see http://api.highcharts.com/highcharts#plotOptions.series.zones
   * @param capsuleSeries - The series from a capsule that will display on the chart.
   * @param color - The color that is being used for the item
   * @return The zones for the item.
   */
  function zonesForCapsuleTime(capsuleSeries: any, color: string): any[] {
    const colorObject = tinycolor(color);
    const dimHide = instanceSettings.isDimmed ? Math.min(ALPHA_DIMMED, colorObject.getAlpha()) : 0;
    const secondColor = colorObject.setAlpha(dimHide).toString('rgb');
    // Clone this so that we can update it.  Typically this is [{ value: ###### }, {dashStyle: 'dot'}]
    const existingZones = _.cloneDeep(capsuleSeries.zones);
    // New zones to be applied
    let capsuleZones = [
      {
        value: 0,
        color: secondColor,
      },
      {
        value: capsuleSeries.endTime - capsuleSeries.startTime,
        color,
      },
      {
        color: secondColor,
      },
    ];

    // If there are existing zones, they are modifying the dashStyle to show uncertainty
    // Since the first of these will only contain the value that defines the end of the default dashStyle,
    // we need to add the capsuleSeries dashStyle so that it is correctly updated in all zone objects prior to it
    // otherwise the uncertain dash style will be added incorrectly
    if (existingZones && existingZones.length > 0 && _.isUndefined(existingZones[0].dashStyle)) {
      existingZones[0].dashStyle = capsuleSeries.dashStyle;
    }

    // Collapse duplicates into one object and concatenate others to the capsuleZones array so we have a complete
    // array
    _.forEach(existingZones, (zone: any) => {
      const dupe = _.find(capsuleZones, ['value', zone.value]);
      if (dupe) {
        _.merge(dupe, zone);
      } else {
        capsuleZones = _.concat(capsuleZones, [zone]);
      }
    });

    // Sort the array by time value for Highcharts, those without a value correctly fall at the end
    const sortedZones = _.sortBy(_.compact(capsuleZones), ['value']);

    // Get the zones with the specified properties so that we can iterate over them and add the properties, as
    // needed, to the zones to be returned so that all zones with values also have a color and dashStyle property.
    const coloredZones = _.filter(sortedZones, 'color');
    const dashStyledZones = _.filter(sortedZones, 'dashStyle');

    // The zone objects that will be returned from this function
    // Clone the sortedZones as we can't update it as we're iterating over it
    const returnZones = _.cloneDeep(sortedZones);

    // A generic function to add a property to any object in the array prior to the given one that does not already
    // have that property defined, otherwise Highcharts sets any property not defined back to it's default
    const adjustZones = (array, property) => {
      _.forEach(array, (zone) => {
        const sortedZoneIndex = _.findIndex(sortedZones, (sortedZone) => {
          return _.isEqual(sortedZone, zone);
        });

        _.forEach(sortedZones, (sortedZone, index) => {
          if (_.isUndefined(returnZones[index][property]) && index < sortedZoneIndex) {
            returnZones[index][property] = zone[property];
          }
        });
      });
    };

    adjustZones(coloredZones, 'color');
    adjustZones(dashStyledZones, 'dashStyle');

    return returnZones;
  }

  /**
   * Updates the corresponding series with new options. Does not redraw the chart.
   *
   * @param items - The items with updated properties.
   * @param updateData - Specifies if series data should be updated or not. True by default
   */
  function updateSeries(items: any[], updateData = true) {
    _.forEach(items, (item: any) => {
      const series = findChartSeries(item.id);
      if (updateData) {
        updateChartSeriesData(series, item.data);
      }
      series.update(_.assign({}, _.omit(item, ['data'])), false);
    });
  }

  /**
   * Updates the data points of the corresponding series. Does not redraw the chart.
   *
   * @param series - The series object
   * @param data - The data points for the series.
   */
  function updateChartSeriesData(series: Highcharts.Series, data: any[]) {
    // SetData is expensive even if no data is present. Skip if the series has no data and we get an empty array
    if (_.isEmpty(series.data) && _.isEmpty(data)) {
      return;
    }

    // We call setData() separately from the update() call because passing false as the fourth argument to
    // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
    // argument for update().
    series.setData(data, false, false, false);
  }

  /**
   * Updates the visibility of the corresponding series based on autoDisabled. Does not redraw the chart.
   *
   * @param items - The items with updated visible property.
   */
  function updateSeriesVisibility(items: any[]) {
    _.forEach(items, (item: any) => {
      const series = findChartSeries(item.id);
      if (series) {
        series.setVisible(!item.autoDisabled, false);
      }
    });
  }

  /**
   * Updates the X extremes on the chart
   *
   * @param redraw - Set to false to prevent redrawing the chart when finished updating extremes
   */
  function updateXExtremes(redraw = true) {
    // NOTE: when being called from the zoom setExtremes callback, the xAxis.setExtremes function is removed by
    // highcharts to avoid an infinite loop.
    if (instanceSettings.chart && _.isFunction(instanceSettings.chart.xAxis[0].setExtremes)) {
      if (!instanceSettings.isCapsuleTime) {
        instanceSettings.chart.xAxis[0].setExtremes(
          instanceSettings.trendStart,
          instanceSettings.trendEnd,
          false,
          false,
        );
        sqCursors.drawCursors(
          instanceSettings.chart,
          capsuleLaneHeight,
          instanceSettings.sqTrendStoreData,
          instanceSettings.sqDurationStoreData,
          instanceSettings.longestCapsuleSeriesDuration,
          instanceSettings.sqCursorStoreData,
          instanceSettings.selectedTimezone.name,
          instanceSettings.items,
          instanceSettings.darkMode,
        );
        drawSelectedRegion({
          xMin: instanceSettings.selectedRegion.min,
          xMax: instanceSettings.selectedRegion.max,
          yMin: 0,
          yMax: 0,
        });
        drawCapsuleRegion();
        positionCapsuleIcons();
        if (instanceSettings.view === TREND_VIEWS.CHAIN) {
          updateLineBreaks();
        }
      } else {
        instanceSettings.chart.xAxis[0].setExtremes(
          0 + instanceSettings.capsuleTimeOffsets.lower,
          instanceSettings.longestCapsuleSeriesDuration + instanceSettings.capsuleTimeOffsets.upper,
          false,
          false,
        );
      }

      if (redraw) {
        chartRedraw();
      }
    }
  }

  /**
   * Adds new series to the chart. Does not redraw the chart.
   *
   * @param items - The items to add.
   */
  function addSeries(items: any[]) {
    _.forEach(items, (item) => {
      const newAxis = createYAxisDefinition(item);
      if (newAxis) {
        instanceSettings.chart.addAxis(newAxis, false, false, false);
      }
      instanceSettings.chart.addSeries(item, false, false);
    });
  }

  /**
   * Removes the corresponding series from the chart.
   *
   * @param items - The items to remove.
   */
  function removeSeries(items: any[]) {
    _.forEach(items, (item: any) => {
      let yAxisToRemove;
      const series = findChartSeries(item.id);

      if (!series) {
        return;
      }

      // If the series is alone on it's y axis, note it so we can find the axes and remove them.
      if (series.yAxis.series.length === 1) {
        yAxisToRemove = _.find(instanceSettings.chart.yAxis, (axis: any) => {
          return axis.series[0] === series;
        });
      }

      series.remove(false);

      // Highcharts doesn't clean up empty axes, so handle this if the axes are now empty
      // We have to identify them prior to the series being removed, but remove them after
      if (yAxisToRemove) {
        yAxisToRemove.remove(false);
      }
    });
  }

  /**
   * Handles a click event on the chart object. Used to drop cursors.
   *
   * @param e - The click event
   */
  function onChartClick(e: MouseEvent) {
    if (e.shiftKey || e.type === 'contextmenu') {
      sqCursors.createCursor(
        instanceSettings.isCapsuleTime,
        instanceSettings.sqTrendStoreData,
        instanceSettings.sqDurationStoreData,
      );
    }
  }

  /**
   * Handles the click event on a point.
   *
   * @param e - The click event
   * @param e.metaKey - Whether the 'meta' key was depressed
   * @param e.ctrlKey - Whether the 'control' key was depressed
   * @param e.point.series.options - Object representing the series clicked
   */
  function onPointClick(e: Highcharts.PointClickEventObject) {
    if (e.shiftKey) {
      sqCursors.createCursor(
        instanceSettings.isCapsuleTime,
        instanceSettings.sqTrendStoreData,
        instanceSettings.sqDurationStoreData,
      );
      return;
    }

    let item = e.point.series.options as any;
    let items = _.filter(instanceSettings.items, ['itemType', item.itemType]);

    if (_.includes(CAPSULE_TYPES, item.itemType)) {
      item = findClosestCapsule(e.chartX, e.chartY);
    }

    // In capsuleTime the selection status of the series always corresponds to the capsule parent because that is
    // what the user sees in the capsules panel.
    if (instanceSettings.isCapsuleTime && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      item = instanceSettings.findCapsuleItem(item.capsuleId);
      items = _.filter(instanceSettings.items, ['itemType', ITEM_TYPES.CAPSULE]);
    }

    if (item && !item.notFullyVisible) {
      instanceSettings.selectItems(item, items, e);
    }
  }

  /**
   * Given chartX and chartY coordinates searches all capsule y-axis and finds the y-axis. Once the y-axis is
   * determined, the chartX value is used to whittle down to the capsule the user clicked.
   *
   * @param chartX - The X position of the mouse relative to the chart in pixels
   * @param chartY - The Y position of the mouse relative to the chart in pixels
   *
   * @return The capsule if found, else undefined
   */
  function findClosestCapsule(chartX: number, chartY: number): Object | undefined {
    const xValue = instanceSettings.chart.xAxis[0].toValue(chartX);
    const matchingAxes = _.filter(instanceSettings.chart.yAxis, (axis) => {
      const top = _.get(axis, 'top');
      const height = _.get(axis, 'height');

      return (
        axis.series && _.startsWith(axis.userOptions.id, TREND_TOP_Y_AXIS_ID) && chartY >= top && chartY < top + height
      );
    });

    if (!matchingAxes.length) {
      return undefined;
    }

    let capsulesAndDistances = [];
    _.forEach(matchingAxes, (axis) => {
      const axisY = axis.toValue(chartY) - axis.min;
      _.forEach(axis.series, (chartItem) => {
        const capsule = instanceSettings.findChartCapsule(chartItem.userOptions.id, xValue);

        if (capsule) {
          capsulesAndDistances.push([capsule, Math.abs(_.get(chartItem.data, '0.plotY') - axisY)]);
        }
      });
    });

    return _.chain(capsulesAndDistances)
      .reduce(
        (closest, capsuleAndDistance) => (capsuleAndDistance[1] < closest[1] ? capsuleAndDistance : closest),
        [undefined, Infinity],
      )
      .head()
      .value();
  }

  /**
   * Manages the Y-Axis as well as ensures the lanes background is drawn properly.
   * If series that are allocated to the same lane share the same axis alignment only one of those series is
   * flagged as the "primary" series. The other series are accessible using the ".series" property.
   *
   * The y-axis labels are drawn by Highcharts so the required offsets are set as part of this process as well.
   */
  function manageYAxis() {
    updateCapsuleAxis();
    const plotBandColors = processYAxisChangesPerLane({
      ...instanceSettings,
      capsuleLaneHeight,
    });
    updatePlotBands(plotBandColors);
    manageLaneAxisLabels();
    drawSelectedRegion({
      xMin: instanceSettings.selectedRegion.min,
      xMax: instanceSettings.selectedRegion.max,
      yMin: 0,
      yMax: 0,
    });
    drawCapsuleRegion();
  }

  /**
   * Get the current lane width for the chart, chart width - yaxis width
   *
   * @return If a number, the pixels currently dedicated to plotting data; if undefined, chart is not created.
   */
  function getLaneWidth(): number | undefined {
    if (instanceSettings.chart && instanceSettings.chartElement) {
      return instanceSettings.chart.clipBox.width;
    } else {
      return undefined;
    }
  }

  function updateXRange(changeInLeft: number, changeInRight: number) {
    if (!instanceSettings.isCapsuleTime) {
      instanceSettings.updateDisplayRangeTimes(
        instanceSettings.trendStart + changeInLeft,
        instanceSettings.trendEnd + changeInRight,
      );
    } else {
      instanceSettings.setCapsuleTimeOffsets(
        instanceSettings.capsuleTimeOffsets.lower + changeInLeft,
        instanceSettings.capsuleTimeOffsets.upper + changeInRight,
      );
    }
  }

  function getYAxesUnderCursor(chartX: number, chartY: number) {
    const ymouse = chartY - capsuleLaneHeight;

    // determine what the index of the lane for the item is so we can calculate the y-offset:
    const lane = chartLanes.getLaneFromYPos(ymouse, { ...instanceSettings, capsuleLaneHeight });
    const seriesByYAxis = getSeriesForYAxisInteraction(lane);
    const scalingRects = computeYAxisScaleRectangles(seriesByYAxis);

    return _.chain(scalingRects as any[])
      .filter((rect) => pointInRectangle(chartX, chartY, rect.x, rect.y, rect.x + rect.width, rect.y + rect.height))
      .flatMap((rect) => {
        // for each rect, return all series axes
        const seriesForAxis = getAxisItem(instanceSettings.items, rect.axis);
        const axisWithSeries = _.find(seriesByYAxis, (singleSeriesAxis) => {
          return _.some(singleSeriesAxis.series, ['id', seriesForAxis.id]);
        });

        return axisWithSeries.series;
      })
      .map(getItemYAxis)
      .reject('options.seeqDisallowZoom')
      .value();
  }

  /**
   * Computes the position and size of the y axis scale regions that allow scroll and zoom.
   * If more than one y-axis is visible, each is returned with its own bounding area.
   *
   * @param seriesByLane - Array of objects representing the Series in a lane.
   * @param seriesByLane.primarySeries - the primary series Object (in case axis are on the same scale)
   * @param seriesByLane.series - Array of all Series objects assigned to that y-Axis
   * @return Returns an array of objects with id, x, y, width and height properties.
   */
  function computeYAxisScaleRectangles(seriesByLane: SeriesGroupedByAxis[]): any[] {
    let leftAxes, rightAxes;
    let boundaryBoxesLeft, boundaryBoxesRight;

    if (!instanceSettings.chart || !instanceSettings.chart.yAxis || !instanceSettings.chart.yAxis.length) {
      return [];
    }

    // If every series is hidden, then return all axes with the area of the entire y-axis region
    if (_.every(seriesByLane, ['axisVisibility', false])) {
      return _.chain(seriesByLane)
        .map('primarySeries')
        .thru(_.flatten)
        .map(getItemYAxis)
        .map((yaxis) => ({
          x: 0,
          y: 0,
          width: instanceSettings.chart.margin[3],
          height: instanceSettings.chart.chartHeight,
          axis: yaxis,
        }))
        .value();
    }

    /**
     * Returns all the chart axes that match the given opposite value. It's used to get all the axes on the
     * right or left.
     *
     * @param opposite - true if the axis should be on the right, false (or undefined) if they are on the
     *   left
     * @returns Highcharts Axis Objects
     */
    function getAxis(opposite: boolean): Highcharts.Axis[] {
      return _.chain(seriesByLane)
        .map('primarySeries')
        .map(getItemYAxis)
        .filter(
          (axis) =>
            _.get(axis, 'userOptions.visible') &&
            (_.get(axis, 'userOptions.opposite') === opposite || _.get(axis, 'userOptions.opposite') === undefined),
        )
        .orderBy(['offset'], [opposite ? 'asc' : 'desc'])
        .value();
    }

    // Get the y-axis for each series
    leftAxes = getAxis(false);
    rightAxes = getAxis(true);

    /**
     * Returns the axis boundary boxes for a given array of axis objects
     *
     * @param opposite - true if the axes array contains axis that are on the right, false if they are
     *   on the left
     * @param axes - Array of Highcharts Axis Objects
     * @returns array defining a y-axis boundary
     */
    function getBoundaryBoxes(opposite: boolean, axes: Highcharts.Axis[]): any[] {
      let xOffset = opposite
        ? instanceSettings.chart.plotLeft + instanceSettings.chart.plotWidth
        : instanceSettings.chart.plotLeft;
      let prevWidth = 0;

      return _.map(axes, (yaxis: any) => {
        const item = getAxisItem(instanceSettings.items, yaxis);
        let width = yaxis.userOptions.axisWidth;
        const { offset: yOffset, height } = chartLanes.computeLaneValues(item.lane, {
          capsuleLaneHeight,
          chart: instanceSettings.chart,
          isCapsuleTime: instanceSettings.isCapsuleTime,
          items: instanceSettings.items,
        });

        if (!_.isFinite(width)) {
          width = Y_AXIS_MIN_TOTAL_WIDTH;
        }

        if (opposite) {
          // for axis on the right side we need to keep track of the width of the previous axes to ensure we
          // calculate the correct starting point (the axis x position for the second axis on the right
          // corresponds to the left x position of the chart plotbox + the width of the first axis (that's what
          // prevWidth is keeping track of, the accumulated width of the axis before the current one)
          xOffset += prevWidth;
          prevWidth = width;
        } else {
          xOffset -= width;
        }

        return {
          x: xOffset,
          y: yOffset,
          width,
          height,
          axis: yaxis,
        };
      });
    }

    boundaryBoxesLeft = getBoundaryBoxes(false, leftAxes);
    boundaryBoxesRight = getBoundaryBoxes(true, rightAxes);

    return boundaryBoxesLeft.concat(boundaryBoxesRight);
  }

  /**
   * Find the chart series object that matches the id specified
   *
   * @param id - ID value for which to search
   * @return Chart series object; null if not found
   */
  function findChartSeries(id: string): Highcharts.Series {
    return _.find(instanceSettings.chart.series as Highcharts.Series[], {
      options: {
        id,
      },
    });
  }

  /**
   * Trigger a chart redraw. This was pulled out to its own method so that it is easy to add logging and timing
   * around all of the redraws for the chart while debugging.
   */
  function chartRedraw() {
    if (instanceSettings.chart) {
      instanceSettings.chart.redraw();
      instanceSettings.afterChartUpdate?.();
    }
  }

  //region Import Wrappers

  function clipSignalsToLanes() {
    laneClipRect = clipSignalsToLanesImport({
      ...instanceSettings,
      laneClipRect,
      laneWidth: getLaneWidth(),
      capsuleLaneHeight,
    });
  }

  function getSeriesForYAxisInteraction(lane?): SeriesGroupedByAxis[] {
    return getSeriesForYAxisInteractionImport({ ...instanceSettings, lane });
  }

  function getChartSeriesDisplayHeight(): number {
    return getChartSeriesDisplayHeightImport({
      ...instanceSettings,
      capsuleLaneHeight,
    });
  }

  function getItemYAxis(item: { id: string; capsuleSetId: string; itemType: string }): any | undefined {
    return getItemYAxisImport(instanceSettings.chart, item);
  }

  function updatePlotBands(colors: any = {}) {
    return updatePlotBandsImport({
      ...instanceSettings,
      colors,
      capsuleLaneHeight,
    });
  }

  function manageLaneLabelDisplay() {
    manageLaneLabelDisplayImport({
      ...instanceSettings,
      sqTrendStore: instanceSettings.sqTrendStoreData,
      conditionValues: instanceSettings.sqTrendCapsuleSetStoreData,
    });
  }

  function updateYAxisAlignment() {
    return updateYAxisAlignmentImport(instanceSettings);
  }

  function updateSeriesYExtremes(items: readonly any[]) {
    return updateSeriesYExtremesImport({
      items,
      chart: instanceSettings.chart,
    });
  }

  function updateCapsuleAxis() {
    return updateCapsuleAxisImport({
      ...instanceSettings,
      capsuleLaneHeight,
      colors: {},
    });
  }

  function getNumericTickPositions() {
    return getNumericTickPositionsImport({
      ...instanceSettings,
      capsuleLaneHeight,
      getChart: () => instanceSettings.chart,
      getItems: () => instanceSettings.items,
    });
  }

  //endregion

  return {
    destroyChart,
    updateItems: () => {
      const oldItems = _.map(instanceSettings.prevItems, (item) => Object.freeze(item));
      Object.freeze(oldItems);
      const newItems = _.map(instanceSettings.items, (item) => Object.freeze(item));
      Object.freeze(newItems);

      processItems(instanceSettings.items, instanceSettings.prevItems);
      instanceSettings.prevItems = instanceSettings.items;
      instanceSettings.onContentUpdate?.();
    },
    updateXExtremes: () => {
      updateXExtremes(true);
    },
    updateSelectedRegion: () => {
      drawSelectedRegion({
        xMin: instanceSettings.selectedRegion.min,
        xMax: instanceSettings.selectedRegion.max,
        yMin: 0,
        yMax: 0,
      });
      drawCapsuleRegion();
    },
    updateIsPickingMode: () => {
      drawSelectedRegion({
        xMin: instanceSettings.selectedRegion.min,
        xMax: instanceSettings.selectedRegion.max,
        yMin: 0,
        yMax: 0,
      });
      drawCapsuleRegion();
    },
    updateSelectedTimezone: () => {
      if (instanceSettings.chart) {
        instanceSettings.chart.update({
          time: { getTimezoneOffset: timeZoneOffsetFn() },
        });
        sqCursors.drawCursors(
          instanceSettings.chart,
          capsuleLaneHeight,
          instanceSettings.sqTrendStoreData,
          instanceSettings.sqDurationStoreData,
          instanceSettings.longestCapsuleSeriesDuration,
          instanceSettings.sqCursorStoreData,
          instanceSettings.selectedTimezone.name,
          instanceSettings.items,
          instanceSettings.darkMode,
        );
      }
    },
    updateBreaks: () => {
      if (instanceSettings.chart) {
        instanceSettings.chart.xAxis[0].update({ breaks: instanceSettings.breaks }, false);
        // ensure to redraw before attempting to draw the line breaks or the breaks won't be available on the axis.
        chartRedraw();
        updateLineBreaks();
      }
    },
    updateIsDimmed: () => {
      if (instanceSettings.chart) {
        updateSeries(getSeriesProperties(instanceSettings.items));
        chartRedraw();
      }
    },
    updateAnnotatedItemsIds: () => {
      updateAnnotationIcons();
      drawCapsuleRegion();
    },
    reflowTrend,
    syncCursors,
    syncAutoUpdate,
    syncTrendStore,
    updateScope: (newProps) => _.assign(instanceSettings, newProps),
  };
}
